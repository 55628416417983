import { render, staticRenderFns } from "./stage.vue?vue&type=template&id=3b2f2ced&scoped=true"
import script from "./stage.vue?vue&type=script&lang=js"
export * from "./stage.vue?vue&type=script&lang=js"
import style0 from "./stage.vue?vue&type=style&index=0&id=3b2f2ced&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b2f2ced",
  null
  
)

export default component.exports